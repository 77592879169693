import React from 'react'
import { graphql } from 'gatsby'
import AboutUsTemplate from '../../components/pages/aboutUsTemplate'

/*** This is duplicate of /pages/nase-prace/nase-sluzby
 */
const OurServicesSk = ({data}) => (
  <AboutUsTemplate data={data} lang={'sk'}/>
)
export const query = graphql`{
    ourServices: file(relativePath: {eq: "pages/sk/about-us/about_us.md"}) {
        childMarkdownRemark {
            frontmatter {
                title
                subtitle
                image {
                    childImageSharp {
                        fluid( maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                description_title
                description
                section_one {
                    title
                    description
                    paragraphs {
                        text
                        title
                    }
                }
                section_two {
                    title
                    description
                    text
                }
            }
        }
    }
}
`

export default OurServicesSk
