import React from 'react'
import Layout from '../layout'
import SideMenu from '../sideMenu/side-menu'  
import { AnchorClickToNavigateWrapper, getTranslateFunction } from '../../utilities'
/* import ContactForm from '../contactForm/ContactForm' */
import PageTitle from '../pageTitle'
import snarkdown from 'snarkdown'
import Reveal from '../reveal'
import './Pages.scss';
import ContactUs from '../contactUs/ContactUs'


const AboutUsTemplate = ({ data, lang }) => {
  const result = data.ourServices.childMarkdownRemark.frontmatter

  const t = getTranslateFunction(lang)
 
  return (
    <Layout lang={lang} className={'mobile-menu-offset AboutUs'}>
      <AnchorClickToNavigateWrapper>
       
        <section className="about-us-top margin-top">
          <div className="container container-to-xxl-off">
            <div className="row subpage-title align-items-center fix-nav-offset">
              <div className="col-lg-8 col-xxl-11 push-xl-3 push-xxl-none">
                <PageTitle title={result.title} description={ result.description } />
                <p>{result.subtitle}</p>
              </div>
            </div>
          </div>
          </section>
         

        <Reveal>
          <section className="references">
          <div className="d-md-flex">
            <div className="d-none d-lg-block col-md-3 side-menu-height-reference" id="left">
              <SideMenu lang={lang} isGhost={false}/>
            </div>
            <div className="col-lg-9 pr-lg-0">
              {/*<Img alt={'letenky hypoteka inspirativni'} fluid={result.image.childImageSharp.fluid}/>*/}
              <video className='ml-0 ml-md-5 ml-lg-0' autoPlay={true} muted={true} loop={true} controls={false}>
                <source src={'/videos/Narative_anim_16-9.mp4'} type="video/mp4"/>
              </video>

              <div className="col-xl-10 pl-0 pl-md-4 pl-lg-2">
                <Reveal>
                  <section>
                  <div className="container mt-5">
                    <div className="row align-items-center subpage-title mt-5">
                      <div className="col-lg-10">
                        <h2 className='title'>{result.description_title}</h2>
                        <p className={'description-smaller'} dangerouslySetInnerHTML={{ __html: snarkdown(result.description) }}/>
                      </div>
                    </div>
                    <hr className={'mt-4 mb-5'}/>
                  </div>
                  </section>
                </Reveal>

                <Reveal>
                  <section>
                  <div className="container mt-5">
                    <div className="row align-items-center mt-5">
                      <div className="col-lg-10">
                        <h2 className='title' dangerouslySetInnerHTML={{ __html: snarkdown(result.section_one.title) }}></h2>
                        <p className={'description-smaller'} dangerouslySetInnerHTML={{ __html: snarkdown(result.section_one.description) }}/>
                      </div>
                    </div>
                    <div className={'row'}>
                      {result.section_one.paragraphs.map((paragraph) => (
                        <div className="col-12 col-md-6 mt-5 px-3 pr-xl-5" key={paragraph.title}>
                          <h3 className='subtitle'>{paragraph.title}</h3>
                          <p className={'paragraph'} dangerouslySetInnerHTML={{ __html: snarkdown(paragraph.text) }}/>
                        </div>
                      ))}
                    </div>
                    <hr className={'mt-5 mb-5'}/>
                  </div>
                  </section>
                </Reveal>

                <Reveal>
                  <section>
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <h2 className={'mt-2 title'} dangerouslySetInnerHTML={{ __html: snarkdown(result.section_two.title) }}></h2>
                      </div>
                    </div>
                    <div className={'row'}>
                      <div className="col-12 col-xl-6">
                        <p className={'description-smaller'} dangerouslySetInnerHTML={{ __html: snarkdown(result.section_two.description) }}/>
                      </div>
                      <div className="col-12 mt-3 col-xl-6 mt-xl-0">
                        <p className={'paragraph'} dangerouslySetInnerHTML={{ __html: snarkdown(result.section_two.text) }}/>
                      </div>
                    </div>
                  </div>
                  </section>
                </Reveal>
              </div>
            </div>
          </div>
          </section>
        </Reveal>

      {/*    <Reveal>
          <section className={`${styles.section}`}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 pr-2 pr-md-5'>
                  <div className={styles.blockTitle}>
                    <h3 className={styles.h3}>{content.block_swithcer.title}</h3>
                    <p className={`${styles.description} mt-3`}>{content.block_swithcer.description}</p>
                    <HoverBtn className={styles.emailBtn} handleClick={() => this.scrollBotton('contactForm')}>
                      <div className={`${styles.send} d-flex align-items-center`}>
                        <span className='mr-2'>Kontaktujte nás</span>
                      </div>
                    </HoverBtn>
                  </div>
                </div>
                <div className={`col-lg-6 ${styles.blockSwither}`}>
                  <BlockSwitcher items={content.block_swithcer.list}/>
                </div>
              </div>
            </div>
          </section>
        </Reveal> */}

        <Reveal>
          <ContactUs
            blockClassName={'services'}
            LeftSideTitle={t('References.MoreProjects.Title')}
            LeftSideSubTitle={'Naši klienti'}
            LeftSideLink={'/o-nas/klienti'}
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>

  
     {/*    <Reveal>
          <ContactForm />
        </Reveal> */}
        
      </AnchorClickToNavigateWrapper>
    </Layout>)
}

export default AboutUsTemplate
